import React, { useEffect } from "react";
import "./nyttarsaften.css";
import sketch from "../../sketches/nyttarsaften/nyttarsaften.js";

const Nyttarsaften = () => {
  useEffect(() => {
    sketch.init(document.getElementById("sketch"));
    mixpanel.track("Nyttarsaften");
  }, []);

  return (
    <div className="nyttarsaften">
      <header className="nyttarsaften-header">
        <h1>2019 2020</h1>
        <p>Åsengata 2B</p>
        <p>18:00</p>
      </header>
      <div id="sketch"></div>
    </div>
  );
};

export default Nyttarsaften;
