import React from "react";
import styled from "styled-components";

const cellsInWidth = 10;
const cellSize = window.innerWidth / cellsInWidth;
const cellsInHeight = Math.floor(window.innerHeight / cellSize);

const Layout = styled.main`
  display: grid;
  background-color: #111111;
  width: ${window.innerWidth}px;
  height: ${window.innerHeight}px;
  grid-template-columns: repeat(${cellsInWidth}, 1fr);
  grid-template-rows: repeat(${cellsInHeight}, 1fr);
  grid-auto-flow: column;
  overflow: hidden;
`;

const OddDiv = styled.div`
  height: ${cellSize};
  text-align: center;
  background-color: white;
  /* border-bottom: ${() =>
    Math.random() < 0.5 ? "solid 2px black" : "solid 10px blue"}; */
  transform: rotate3d(1, 1, 1, -85deg) translate3d(100px, 0, -50px);
`;

const EvenDiv = styled(OddDiv)`
  background-color: grey;
  transform: rotate3d(1, 1, 1, 75deg) translate3d(-100px, 0, 20px);
  mix-blend-mode: difference;
`;

const P = styled.p`
  font-size: 100px;
  color: black;
`;

const initGrid = () => {
  const str = "StianSurén";
  const strArr = str.split("");
  let array = [];
  for (let i = 0; i < cellsInHeight * cellsInWidth; i += 1) {
    i % 2 === 0
      ? array.push(
          <EvenDiv key={i}>
            <P>{strArr[i % strArr.length]}</P>
          </EvenDiv>
        )
      : array.push(
          <OddDiv key={i}>
            <P>{strArr[i % strArr.length]}</P>
          </OddDiv>
        );
  }
  return array;
};

const Content = () => {
  const grid = initGrid();
  return <Layout>{grid.map(x => x)}</Layout>;
};

export default Content;
