import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Sketch from "../../components/Sketch";
import Button from "../../components/Button";
import { H1, P, A } from "../../components/Typography";
import { fadeIn } from "../../components/Animation";

import rd from "../../sketches/rd/rd.js";
import nyttarsaften from "../../sketches/nyttarsaften/nyttarsaften.js";
import nyttarsaften_closeUp from "../../sketches/nyttarsaften_closeUp/nyttarsaften_closeUp.js";

const sketches = [nyttarsaften, nyttarsaften_closeUp];

const Wrapper = styled.div`
  color: #f47046;
`;

const Header = styled.header`
  padding: 4vw calc(16px + 5vw);
  z-index: 1;
  opacity: 0;
  animation: ${fadeIn} ease-out 1.6s;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  mix-blend-mode: difference;
`;

const Footer = styled.footer`
  animation: ${fadeIn} ease-out 1.6s;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;

  opacity: 0;
  padding: calc(16px + 5vw);
  position: absolute;
  bottom: 0;

  mix-blend-mode: difference;
`;

const Forside = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    mixpanel.track("Forside");
  }, []);

  return (
    <Wrapper>
      <Sketch sketch={sketches[index % sketches.length]} />
      <Header>
        <H1>Stian Surén</H1>
        <Button onClick={() => setIndex(index + 1)}>
          {"0" + ((index % sketches.length) + 1) + " ->"}
        </Button>
      </Header>
      <Footer>
        <P>stiansuren@gmail.com</P>
        <P>
          <A href="https://linkedin.com/in/stiansuren/">Linkedin &rarr;</A>
        </P>
      </Footer>
    </Wrapper>
  );
};

export default Forside;
