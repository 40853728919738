import React, { useRef, useState } from "react";
import { Canvas, useFrame } from "react-three-fiber";

const Sphere = props => {
  const [speed, setSpeed] = useState(props.initialSpeed);
  const [acc, setAcc] = useState(-50);
  const [time, setTime] = useState([props.startTime, Date.now()]);

  const mesh = useRef();

  useFrame(() => {
    const deltaTime = (time[1] - time[0]) / 1000;

    mesh.current.position.y +=
      speed * deltaTime + 0.5 * acc * Math.pow(deltaTime, 2);

    setSpeed(speed + acc * deltaTime);
    setTime([time[1], Date.now()]);

    (Math.abs(speed) > 35) & (Math.sign(speed) === Math.sign(acc)) &&
      setAcc(acc * -1);
  });

  return (
    <mesh {...props} ref={mesh} scale={[1, 1, 1]}>
      <sphereBufferGeometry attach="geometry" args={[1, 16, 16]} />
      <meshBasicMaterial attach="material" color="blue" />
    </mesh>
  );
};

export const Sketch = () => {
  const spheres = initSpheres();
  const startTime = useRef(Date.now());

  return (
    <Canvas>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <pointLight position={[-10, 10, 10]} />
      {spheres.map(({ key, position, initialSpeed }) => (
        <Sphere
          key={key}
          position={position}
          initialSpeed={initialSpeed}
          startTime={startTime.current}
        />
      ))}
    </Canvas>
  );
};

const initSpheres = () => {
  const arr = [];
  for (let i = -50; i < 50; i += 10) {
    arr.push({
      key: i,
      position: [i, 12, -10],
      initialSpeed: 0.2 + 0.5 * Math.random()
    });
  }
  return arr;
};
