import * as THREE from 'three';
import vertexShader from './shaders/vertexShader.glsl';
import fragmentShader from './shaders/fragmentShader.glsl';

let camera, scene, renderer, timeStart;

const uniforms = { time: { value: 0.0 } };
const bgcolor = new THREE.Color(0x000000);

function init(element) {

	timeStart = new Date().getTime();

	scene = new THREE.Scene();
	scene.background = bgcolor;

	camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.01, 10 );
	camera.position.z = 1;

	renderer = new THREE.WebGLRenderer( { antialias: true } );
	renderer.setSize( window.innerWidth, window.innerHeight );
	element.appendChild( renderer.domElement );

	initParticles();

	window.addEventListener("resize", onWindowResize, false);
}

const initParticles = function() {

	const material = new THREE.ShaderMaterial({
		uniforms: uniforms,
		vertexShader: vertexShader,
		fragmentShader: fragmentShader,
		side: THREE.DoubleSide,
		transparent: true
	});

	const geometry = new THREE.PlaneBufferGeometry( 1, 1, 1 );
	
	const mesh = new THREE.Mesh( geometry, material );

	scene.add( mesh );
}

function animate() {
	requestAnimationFrame( animate );

	const now = new Date().getTime();
	uniforms.time.value = (now - timeStart) / 1000;

	scene.children[0].rotation.x += -0.003;

	renderer.render( scene, camera );
}

const onWindowResize = function() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
};

export default {
  init: function(element) {
    init(element);
    animate();
  }
};
