import React from "react";
import { ThreeWrapper } from "../../components/ThreeWrapper";
import CannonSketch from "../../sketches/cannon-tutorial";

export default function Cannon() {
  return (
    <>
      <nav>
        <ul>
          <li>
            <a href="watermelon">Watermelon</a>
          </li>
          <li>
            <a href="banana">Banana</a>
          </li>
          <li>
            <a href="strawberry">Strawberry</a>
          </li>
        </ul>
      </nav>
      <ThreeWrapper>
        <CannonSketch />
      </ThreeWrapper>
    </>
  );
}
