import styled from "styled-components";

export const H1 = styled.h1`
  font-size: calc(24px + 2vmin);
  margin: 0;

  text-align: left;
  align-self: center;
`;

export const P = styled.p`
  font-size: calc(12px + 2vmin);
  margin: 0;
`;

export const A = styled.a`
  display: block;
  &:visited {
    color: inherit;
  }

  &:hover {
    transform: translateY(-5px);
    opacity: 0.8;
  }
`;
