import React, { useCallback, useState, useMemo } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  Canvas,
  useLoader,
  extend,
  useThree, // eslint-disable-next-line
  ReactThreeFiber,
} from "react-three-fiber";
import { Physics, useBox } from "use-cannon";
import { v4 as uuid } from "uuid";

extend({ OrbitControls });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: ReactThreeFiber.Object3DNode<
        OrbitControls,
        typeof OrbitControls
      >;
    }
  }
}
type GroundType = {
  width: number;
  position: THREE.Vector3;
};
type LetterType = {
  position: THREE.Vector3;
  textGeometry: TextGeometryType;
};
type TextGeometryType = {
  textGeometry: THREE.TextGeometry;
  size: THREE.Vector3;
};
type MenuItemType = {
  children: string;
  menuIndex: number;
};
type BoxBoundaryType = {
  size: THREE.Vector3;
};

const Orbit: any = () => {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  return <orbitControls args={[camera, domElement]} />;
};

const BoxBoundary = ({ size }: BoxBoundaryType) => {
  return (
    <mesh>
      <boxBufferGeometry attach="geometry" args={[size.x, size.y, size.z]} />
      <meshNormalMaterial attach="material" color="white" wireframe />
    </mesh>
  );
};

const Ground = ({ width, position }: GroundType) => {
  const size = new THREE.Vector3(width + 6, 0.1, 3);
  const [ref] = useBox(() => ({
    mass: 0,
    position: [position.x, position.y, position.z],
    rotation: [0, 0, 0],
    args: [size.x / 2, size.y / 2, size.z / 2],
  }));

  return (
    <mesh ref={ref}>
      <boxBufferGeometry attach="geometry" args={[size.x, size.y, size.z]} />
      <meshStandardMaterial attach="material" color="white" wireframe />
    </mesh>
  );
};

const Letter = ({ position, textGeometry }: LetterType) => {
  const [ref] = useBox(() => ({
    mass: 1,
    position: [position.x, position.y, position.z],
    args: [
      textGeometry.size.x / 2,
      textGeometry.size.y / 2,
      textGeometry.size.z / 2,
    ],
  }));

  const [isHovered, setIsHovered] = useState(false);
  const onHover = useCallback((e, value) => {
    e.stopPropagation();
    setIsHovered(value);
    value && console.log(textGeometry.textGeometry);
  }, []);

  return (
    <mesh
      ref={ref}
      receiveShadow
      castShadow
      onPointerOver={(e) => onHover(e, true)}
      onPointerOut={(e) => onHover(e, false)}
    >
      <primitive object={textGeometry.textGeometry} attach="geometry" />
      <meshNormalMaterial attach="material" color="green" />
      <BoxBoundary size={textGeometry.size} />
    </mesh>
  );
};

const createTextGeometry = (text: string, config: any) => {
  const textGeometry = new THREE.TextGeometry(text, config);
  textGeometry.computeBoundingBox();
  textGeometry.center();
  const size =
    textGeometry?.boundingBox?.getSize(new THREE.Vector3()) ||
    new THREE.Vector3();
  return { textGeometry: textGeometry, size: size };
};

const MenuItem = ({ children, menuIndex }: MenuItemType) => {
  const font = useLoader(THREE.FontLoader, "IBM_Plex_Serif_Bold.json");
  const config = useMemo(
    () => ({
      font,
      size: 3,
      height: 0.4,
      curveSegments: 24,
      bevelEnabled: true,
      bevelThickness: 0.9,
      bevelSize: 0.3,
      bevelOffset: 0,
      bevelSegments: 10,
    }),
    [font]
  );

  const textGeometries = children
    .split("")
    .map((text) => createTextGeometry(text, config));

  const letterSpacing = 0.2;

  const initialValue: number[] = [];
  const textOffsets = textGeometries.reduce((acc, cur, index, array) => {
    const currentLetterWidth = cur.size.x;
    const previousLetterWidth = index > 0 ? array[index - 1].size.x : 0;
    const distToFirstCenter =
      index > 0
        ? acc[acc.length - 1] +
          (currentLetterWidth + previousLetterWidth) / 2 +
          letterSpacing
        : 0;
    acc.push(distToFirstCenter);
    return acc;
  }, initialValue);

  const width = [...textOffsets].pop() || 15;
  const groundPosition = new THREE.Vector3(width / 2, 0, menuIndex * 5);

  return (
    <group>
      {textGeometries.map((textGeometry, index) => (
        <Letter
          position={new THREE.Vector3(textOffsets[index], 5, menuIndex * 5)}
          textGeometry={textGeometry}
          key={uuid()}
        />
      ))}
      <Ground width={width} position={groundPosition} />
    </group>
  );
};

export default function CannonSketch() {
  const menu = ["Straw", "Naaw", "Laaaw", "Cherry"];

  return (
    <Canvas
      shadowMap
      sRGB
      gl={{ alpha: false }}
      orthographic
      camera={{
        position: [-10, 10, 10],
        near: -1,
        far: 100,
        zoom: 30,
      }}
    >
      <Orbit />
      <hemisphereLight intensity={0.35} />
      <spotLight
        position={[10, 10, 10]}
        angle={0.3}
        penumbra={1}
        intensity={2}
        castShadow
      />
      <Physics gravity={[0, -50, 0]}>
        <axesHelper position={[0, 0, 0]} args={[10]} />
        {menu.map((text, index) => (
          <MenuItem key={uuid()} menuIndex={index}>
            {text}
          </MenuItem>
        ))}
      </Physics>
    </Canvas>
  );
}
