import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Nyttarsaften from "./Routes/Nyttarsaften/Nyttarsaften";
import Forside from "./Routes/Forside/Forside";
import Three from "./Routes/Three/Three";
import DivThree from "./Routes/DivThree/DivThree";
import Cannon from "./Routes/Cannon-Tutorial/Cannon";
import Work from "./Routes/Work/Work";

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/nyttarsaften">
          <Nyttarsaften />
        </Route>
        <Route path="/three">
          <Three />
        </Route>
        <Route path="/divthree">
          <DivThree />
        </Route>
        <Route path="/cannon">
          <Cannon />
        </Route>
        <Route path="/work">
          <Work />
        </Route>
        <Route path="/">
          <Forside />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
