import React from "react";
import styled, { keyframes } from "styled-components";
import { P } from "./Typography";

type Props = {
  children: string;
  onClick: () => void;
};

const onHover = keyframes`  
  from {}
  to {
    transform: perspective(500px) translateY(-4px) translateZ(-2px);
    color: #f47046;
  }
`;

const onPress = keyframes`
  from{}
  to {
    transform: rotate3d(1, 1, 1, -2deg) translateY(4px);;
    opacity: 0.4;
  }
`;

const StyledButton = styled.button`
  width: fit-content;
  background-color: transparent;
  color: white;
  border: none;
  border-bottom: 2px solid;
  justify-self: end;
  align-self: center;
  padding-bottom: 1rem;

  @media (hover: hover) {
    &:hover {
      animation: ${onHover} ease-out 0.2s;
      animation-fill-mode: forwards;
      cursor: pointer;
    }
  }

  &:active {
    color: #f47046;
    animation: ${onPress} ease-out 0.04s;
    animation-fill-mode: forwards;
  }

  &:focus {
    outline: none;
  }
`;

const Button: React.FC<Props> = ({ onClick, children }) => {
  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    onClick();
    e.currentTarget.blur();
  };

  return (
    <StyledButton onClick={handleOnClick}>
      <P>{children}</P>
    </StyledButton>
  );
};

export default Button;
