import React, { useEffect } from "react";
import styled from "styled-components";

interface Props {
  sketch: any;
}

const Div = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

function Sketch({ sketch }: Props) {
  useEffect(() => {
    const div: any = document.getElementById("sketch");

    div.childNodes[0] && div.childNodes[0].remove();
    sketch.init(div);
  }, [sketch]);

  return <Div id="sketch"></Div>;
}

export default Sketch;
