import React from "react";
import styled from "styled-components";
import { ThreeWrapper } from "../../components/ThreeWrapper";
import { Sketch } from "../../sketches/gravity/gravity";

const Layout = styled.main`
  display: grid;
  background-color: pink;
  width: 100vw;
  height: 100vh;
  grid-template-rows: 1fr auto 1fr;
  grid-template-columns: 1fr auto 1fr;
`;

const H1 = styled.h1`
  margin: 0 auto;
  grid-area: 2 / 2 / 2 / 2;
  font-size: 200px;
  color: teal;
  text-align: center;
`;

const Three = () => {
  return (
    <>
      <Layout>
        <H1>threeeeeee</H1>
      </Layout>
      <ThreeWrapper>
        <Sketch />
      </ThreeWrapper>
    </>
  );
};

export default Three;
