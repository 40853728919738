import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: difference;
`;

export const ThreeWrapper = ({ children }: any) => {
  return <Wrapper>{children}</Wrapper>;
};
